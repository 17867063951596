<template>
  <div class="ParametersModels">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th scope="col">{{ $t('PARAMETERS_MODELS_SKU') }}</th>
                <th scope="col">{{ $t('PARAMETERS_MODELS_PRODUCT_NAME') }}</th>
                <th scope="col">{{ $t('PARAMETERS_MODELS_PRODUCT_COMPANY') }}</th>
                <th scope="col">{{ $t('PRICE') }}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="model in settingsModels" :key="model.sku">
                <td>{{ model.sku }}</td>
                <td>{{ model.name }}</td>
                <td>{{ model.company }}</td>
                <td>
                  <div class="price-input form-group">
                    <div class="input-group mb-2">
                      <input :disabled="!model.enabled" class="text-right" v-model="model.priceITA" type="number" />
                      <div class="input-group-append">
                        <div class="input-group-text">€</div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="price-input form-group">
                    <div class="input-group mb-2">
                      <input :disabled="!model.enabled" class="text-right" v-model="model.priceUSA" type="number" />
                      <div class="input-group-append">
                        <div class="input-group-text">$</div>
                      </div>
                    </div>
                  </div>
                </td>
                <td style="vertical-align: middle">
                  <button v-if="model.enabled" class="Button Button__edit" @click="saveRow(model)">
                    <ContentSave />
                    <span class="Button__label">{{ $t('ACTION_SAVE') }}</span>
                  </button>
                  <p v-if="!model.enabled">{{ $t('PARAMETERS_PRODUCT_DISABLED') }}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ContentSave from 'vue-material-design-icons/ContentSave.vue'

export default {
  name: 'parametersModels',
  components: {
    ContentSave
  },
  computed: {
    ...mapGetters(['settingsModels'])
  },
  methods: {
    saveRow(data) {
      this.$store.dispatch('SETTINGS_UPDATE_MODEL', {
        id: data.sku,
        priceITA: Number(String(data.priceITA).replace(',', '.')),
        priceUSA: Number(String(data.priceUSA).replace(',', '.'))
      })
    }
  },
  created() {
    if (!this.settingsModels || this.settingsModels.length === 0) {
      this.$store.dispatch('SETTINGS_GET_MODELS')
    }
  }
}
</script>

<style scoped lang="scss">
.ParametersModels {
  padding: 30px 0;
}
</style>
